import { configureStore } from "@reduxjs/toolkit";

// reducers
import SideBarReducer from "./sidebar";
import ToastReducer from "./toast";
import AuthenticationReducer from "./authentication";

const preloadedState = {
  authentication: {
    value: {
      isLogged: false,
      loginData: null
    }
  }
};

export const store = configureStore({
  reducer: {
    sidebar: SideBarReducer,
    toast: ToastReducer,
    authentication: AuthenticationReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// Optional: Add store listener for debugging
store.subscribe(() => {
  console.log('Store updated:', store.getState());
});
