import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userApi } from "../../api/userApi";

const initialState = {
  value: {
    isLogged: false,
    loginData: null,
    token: localStorage.getItem('token'),
  },
};

export const authenticateUser = createAsyncThunk(
  "authentication/user",
  async (userData) => {
    try {
      // If we already have user data (from registration), use it directly
      if (userData._id) {
        localStorage.setItem('token', userData.token);
        return userData;
      }
      // Otherwise, attempt to login
      const response = await userApi.login(userData);
      localStorage.setItem('token', response.token);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.value = {
        isLogged: true,
        loginData: action.payload,
        token: action.payload.token,
      };
    },
    logout: (state) => {
      localStorage.removeItem('token');
      state.value = {
        isLogged: false,
        loginData: null,
        token: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.value = {
          isLogged: true,
          loginData: action.payload,
          token: action.payload.token,
        };
      })
      .addCase(authenticateUser.rejected, (state) => {
        state.value = {
          isLogged: false,
          loginData: null,
          token: null,
        };
      })
      .addCase(authenticateUser.pending, (state) => {
        state.value = {
          isLogged: false,
          loginData: null,
          token: null,
        };
      });
  },
});

export const { updateUser, logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
