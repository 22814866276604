import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import {
  Dashboard,
  Person,
  School,
  Work,
  Assignment,
  Forum,
  VideoCall,
  Assessment,
} from "@mui/icons-material";

const DRAWER_WIDTH = 280;

export default function SideBar() {
  const userData = useSelector((state) => state?.authentication?.value?.loginData) || {};
  
  const menuItems = [
    { text: 'Dashboard', icon: <Dashboard />, path: '/dashboard' },
    { text: 'Profile', icon: <Person />, path: '/profile' },
    { text: 'Learning Modules', icon: <School />, path: '/learning' },
    { text: 'Job Search', icon: <Work />, path: '/jobs' },
    { text: 'Resume Builder', icon: <Assignment />, path: '/resume' },
    { text: 'Mock Interviews', icon: <VideoCall />, path: '/interviews' },
    { text: 'Community', icon: <Forum />, path: '/community' },
    { text: 'Assessments', icon: <Assessment />, path: '/assessments' },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: 'background.paper',
          borderRight: '1px solid rgba(255, 255, 255, 0.12)',
        },
      }}
    >
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar
          sx={{ 
            width: 80, 
            height: 80, 
            mb: 2, 
            bgcolor: 'primary.dark',
            border: '2px solid',
            borderColor: 'primary.main' 
          }}
        >
          {userData?.name?.[0] || 'G'}
        </Avatar>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {userData?.name || 'Guest'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {userData?.email || ''}
        </Typography>
      </Box>
      
      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }} />
      
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            component={Link}
            to={item.path}
            key={item.text}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(144, 202, 249, 0.08)',
              },
              '&.active': {
                backgroundColor: 'rgba(144, 202, 249, 0.16)',
              },
              py: 1.5,
            }}
          >
            <ListItemIcon sx={{ color: 'primary.light' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
