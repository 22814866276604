import React, { useEffect, useRef, useCallback } from "react";
import { 
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Person,
  Email,
  Phone,
  Visibility,
  VisibilityOff,
  LockOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { authenticateUser } from "../library/store/authentication";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { userApi } from "../api/userApi";
import { store } from "../library/store/store";

export default function RegisterPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMounted = useRef(true);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleFormSubmit = useCallback(async (data, formikHelpers) => {
    if (!isMounted.current) return;

    try {
      const response = await userApi.createUser(data);
      
      if (isMounted.current) {
        await dispatch(authenticateUser(response));
        
        const authState = store.getState().authentication;

        if (authState?.value?.loginData) {
          toast.success("Registration successful!");
          history.push("/dashboard");
        } else {
          toast.error("Authentication failed");
        }
      }
    } catch (error) {
      if (isMounted.current) {
        toast.error(error.response?.data?.message || "Registration failed");
      }
    } finally {
      if (isMounted.current) {
        formikHelpers.setSubmitting(false);
      }
    }
  }, [dispatch, history]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    location: Yup.string(),
    phone: Yup.string(),
    deviceID: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      email: "",
      phone: "",
      deviceID: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleFormSubmit
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 2,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            '&:hover': {
              boxShadow: '0 0 20px rgba(144, 202, 249, 0.16)',
            },
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
              Sign Up
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Enter your details to create an account
            </Typography>
          </Box>

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                <TextField
                  fullWidth
                  label="Full Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  label="Phone Number"
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  sx={{
                    mt: 2,
                    bgcolor: 'primary.main',
                    color: 'common.white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                    height: 48,
                  }}
                >
                  {isSubmitting ? 'Registering...' : 'Register'}
                </Button>

                <Typography 
                  variant="body2" 
                  align="center"
                  sx={{ mt: 2 }}
                >
                  Already have an account?{' '}
                  <Link 
                    to="/login"
                    style={{ 
                      color: '#90caf9',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Log In
                  </Link>
                </Typography>
              </Box>
            </Form>
          </FormikProvider>
        </Paper>
      </Container>
    </Box>
  );
}
