import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Button,
} from "@mui/material";
import {
  Timeline,
  Assignment,
  People,
  TrendingUp,
} from "@mui/icons-material";

export default function DashboardPage() {
  const userData = useSelector((state) => state.authentication?.value?.loginData);

  const stats = [
    { icon: <Timeline />, title: 'Learning Progress', value: '60%', color: '#90caf9' },
    { icon: <Assignment />, title: 'Tasks Completed', value: '12/15', color: '#81c784' },
    { icon: <People />, title: 'Network Size', value: '45', color: '#ffb74d' },
    { icon: <TrendingUp />, title: 'Skills Gained', value: '8', color: '#f48fb1' },
  ];

  return (
    <Box sx={{ p: 4, marginLeft: '30px', bgcolor: 'background.default', minHeight: '100vh' }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: 600 }}>
        Welcome back, {userData?.name || 'Guest'}!
      </Typography>

      <Grid container spacing={3}>
        {/* Stats Cards */}
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.title}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 2,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'rgba(255, 255, 255, 0.12)',
                '&:hover': {
                  boxShadow: '0 0 20px rgba(144, 202, 249, 0.16)',
                  borderColor: 'primary.main',
                },
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <Box sx={{ 
                color: stat.color, 
                mb: 2,
                '& svg': { fontSize: 40 }
              }}>
                {stat.icon}
              </Box>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {stat.value}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {stat.title}
              </Typography>
            </Paper>
          </Grid>
        ))}

        {/* Next Steps Card */}
        <Grid item xs={12} md={8}>
          <Card sx={{ 
            bgcolor: 'background.paper',
            '&:hover': {
              boxShadow: '0 0 20px rgba(144, 202, 249, 0.16)',
            },
            transition: 'all 0.3s ease-in-out',
          }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Your Career Journey
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 1 }}>Overall Progress</Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={60} 
                  sx={{ 
                    height: 8, 
                    borderRadius: 4,
                    bgcolor: 'rgba(144, 202, 249, 0.16)',
                    '& .MuiLinearProgress-bar': {
                      bgcolor: 'primary.main',
                    }
                  }} 
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    Continue Learning
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    Practice Interview
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Quick Actions Card */}
        <Grid item xs={12} md={4}>
          <Card sx={{ 
            bgcolor: 'background.paper',
            '&:hover': {
              boxShadow: '0 0 20px rgba(144, 202, 249, 0.16)',
            },
            transition: 'all 0.3s ease-in-out',
          }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Quick Actions
              </Typography>
              <Button
                variant="text"
                fullWidth
                sx={{ 
                  justifyContent: 'flex-start', 
                  mb: 1,
                  '&:hover': {
                    bgcolor: 'rgba(144, 202, 249, 0.08)',
                  }
                }}
              >
                Update Resume
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{ justifyContent: 'flex-start', mb: 1 }}
              >
                Browse Jobs
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{ justifyContent: 'flex-start', mb: 1 }}
              >
                Network
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
